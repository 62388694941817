import React, { useEffect, useState, useRef } from "react";
import { getRandomColor, createImageFromInitials } from "../Utils";
import Calendar from "../../assets/images/Calendar.svg";
import DatePicker from "react-datepicker";
import { Button } from "react-bootstrap";
import Select from "react-select";
import CommentSection from "./Comment";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import DeleteIcon from "../../assets/images/trash.svg";
import { useDispatch, useSelector } from "react-redux";
import { StepProgressBar } from "../Utils";
import { getTaskDetails, postStrategyFunctions } from "../../store/actions";
import ChevronRight from "../../assets/images/Chevron-right.svg";
import UploadFiles from "../UploadFiles";
import QuillEditor from "../../utils/QuillEditor";
import AddIcon from "../../assets/images/add.svg";

function TaskSidebar({
  isNewTask,
  selectedTaskMilestoneId,
  breadcrumbs,
  empData,
  statusData,
  priorityData,
  mileStonesData,
  selectedTaskId,
  onClose,
  handleTaskDelete,
  isSubTaskVisible,
  setIsSubTaskVisible,
}) {
  const dispatch = useDispatch();

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [task, setTask] = useState([]);
  const [taskName, setTaskName] = useState("");
  const [assignedTo, setAssignedTo] = useState([]);
  const [desc, setDesc] = useState("");
  const [status, setStatus] = useState("");
  const [priority, setPriority] = useState("");
  const [milestone, setMilestone] = useState("");
  const [duedate, setDuedate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [comments, setComments] = useState("");
  const [subtasks, setSubtasks] = useState("");
  const [milestoneId, setMilestoneId] = useState("");
  const [collabarators, setCollabarators] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [isSubTaskSidebarOpen, setIsSubTaskSidebarOpen] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState("");
  const [subTaskId, setSubTaskId] = useState("");
  const [isNewSubTask, setIsNewSubTask] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  // const [AddSubTaskVisible, setAddSubTaskVisible] = useState(false);
  const steps = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5", "Step 6"];
  const [progress, setProgress] = useState(0);
  const getSingleTaskDetails = useSelector(
    (state) => state.getSingleTaskDetails
  );
  const selectedSubMenuPermission = useSelector(
    (state) => state.selectedSubMenuPermission
  );
  const [fullScreenMode, setFullScreenMode] = useState(false);

  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  const getProgress = (step) => {
    if (step === 0) {
      return 0;
    } else if (step === 20) {
      return 1;
    } else if (step === 40) {
      return 2;
    } else if (step === 60) {
      return 3;
    } else if (step === 80) {
      return 4;
    } else {
      return 5;
    }
  };

  useEffect(() => {
    if (
      isNewTask === false &&
      getSingleTaskDetails &&
      getSingleTaskDetails.message &&
      getSingleTaskDetails.message.length > 0
    ) {
      setCurrentTaskId(getSingleTaskDetails.message[0].id);
      setTask(getSingleTaskDetails.message);
      setMilestoneId(getSingleTaskDetails.message[0].milestone_id);
      setTaskName(getSingleTaskDetails.message[0].name);
      setDesc(getSingleTaskDetails.message[0].description);
      setCurrentUser(getSingleTaskDetails.message[0].emp_id);
      setAssignedTo(
        getSingleTaskDetails.message[0].assigned_to.filter(
          (employee) => employee.is_active
        )
      );
      setStatus({
        label: getSingleTaskDetails.message[0].status,
        value: getSingleTaskDetails.message[0].status,
      });
      setPriority({
        label: getSingleTaskDetails.message[0].priority,
        value: getSingleTaskDetails.message[0].priority,
      });

      const filteredData = mileStonesData?.filter(
        (item) => item.value === getSingleTaskDetails.message[0].milestone_id
      );
      if (filteredData && filteredData[0]) {
        setMilestone({
          label: filteredData[0].label,
          value: filteredData[0].value,
        });
      } else {
        console.error(
          `No match found for id: ${getSingleTaskDetails.message[0].milestone_id}`
        );
      }
      setDuedate(getSingleTaskDetails.message[0].exp_end_date);
      setStartDate(getSingleTaskDetails.message[0].exp_start_date);
      setComments(
        getSingleTaskDetails.message[0].comments.map((item) => ({
          ...item,
          icon: createImageFromInitials(
            30,
            item.comment_by[0].name,
            getRandomColor(item.comment_by[0].name)
          ),
        }))
      );
      setSubtasks(getSingleTaskDetails.message[0].subtasks);
      setCollabarators(getSingleTaskDetails.message[0].collaberators);
      setCurrentStep(getProgress(getSingleTaskDetails.message[0].progress));
      setProgress(getSingleTaskDetails.message[0].progress);
    }
  }, [getSingleTaskDetails]);

  useEffect(() => {
    if (isNewTask) {
      setTask("");
      setTaskName("");
      setPriority("");
      setStatus("");
      setDuedate("");
      setStartDate("");
      setAssignedTo([]);
      setProgress("");
      setDesc("");
      setComments([]);
      setCollabarators([]);
      setCurrentStep("");
      setSubtasks([]);
      const filteredData = mileStonesData?.filter(
        (item) => item.value === selectedTaskMilestoneId
      );
      console.log("filteredData", filteredData);
      if (filteredData && filteredData[0]) {
        setMilestone({
          label: filteredData[0].label,
          value: filteredData[0].value,
        });
      }
    }
  }, [isNewTask]);

  useEffect(() => {
    setTask("");
    setTaskName("");
    setPriority("");
    setStatus("");
    setDuedate("");
    setStartDate("");
    setAssignedTo([]);
    setProgress("");
    setDesc("");
    setComments([]);
    setCollabarators([]);
    setCurrentStep("");
    const filteredData = mileStonesData?.filter(
      (item) => item.value === selectedTaskMilestoneId
    );
    if (filteredData && filteredData[0]) {
      setMilestone({
        label: filteredData[0].label,
        value: filteredData[0].value,
      });
    }
  }, [isSubTaskSidebarOpen]);

  const handleSubTaskDelete = () => {
    let data = [
      {
        task_id: subTaskId,
        is_active: false,
      },
    ];
    dispatch(
      postStrategyFunctions(data, "sub_task", false, false, false, true)
    );
    onClose();
  };

  const handleSubtaskNav = () => {
    setIsSubTaskSidebarOpen(true);
    setSubTaskId("");
    setIsSubTaskVisible(false);
  };
  const handleSubtaskSideBar = (id) => {
    setIsSubTaskSidebarOpen(true);
    setSubTaskId(id);
    dispatch(getTaskDetails(id));
    setIsNewSubTask(true);
  };

  const handleStepClick = (stepIndex) => {
    console.log("stepindex", stepIndex);
    setCurrentStep(stepIndex);
    setProgress(stepIndex * 20);
  };

  const handleFullScreen = () => {
    setFullScreenMode(true);
  };

  const handleSideNav = () => {
    setFullScreenMode(false);
  };

  const handleOwnerChange = (e) => {
    setAssignedTo(e);
  };

  const handleStatusSelect = (selectedOptions) => {
    setStatus(selectedOptions);
  };

  const handlePrioritySelect = (selectedOptions) => {
    setPriority(selectedOptions);
  };
  const handleMilestoneSelect = (selectedOptions) => {
    setMilestone(selectedOptions);
  };

  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleDescOnChange = (content) => {
    setDesc(content);
  };

  const handleSave = () => {
    if (isSubTaskSidebarOpen) {
      if (isNewSubTask) {
        let assignedToList = [];
        if (Array.isArray(assignedTo)) {
          assignedToList = assignedTo;
        } else {
          assignedToList = [assignedTo];
        }
        const requiredAssignedData = empData.map((item) => ({
          key: item.value,
          is_active: assignedToList.some(
            (selectedItem) => selectedItem.value === item.value
          ),
        }));
        let data = [
          {
            bi_id: localStorage.getItem("bi_id"),
            prod_id: localStorage.getItem("product_id"),
            parent_task_id: currentTaskId,
            task_name: taskName,
            assigned_to: requiredAssignedData,
            start_date: startDate,
            due_date: duedate,
            status: status.value || "",
            priority: priority.value || "",
            milestone: milestone.value || "",
            progress: progress,
            description: desc,
            collaberators: [],
            is_active: true,
            task_id: subTaskId,
          },
        ];
        dispatch(
          postStrategyFunctions(data, "sub_task", false, false, false, true)
        );
        onClose();
      } else {
        let assignedToList = [];
        if (Array.isArray(assignedTo)) {
          assignedToList = assignedTo;
        } else {
          assignedToList = [assignedTo];
        }
        const requiredAssignedData = empData.map((item) => ({
          key: item.value,
          is_active: assignedToList.some(
            (selectedItem) => selectedItem.value === item.value
          ),
        }));
        let data = [
          {
            bi_id: localStorage.getItem("bi_id"),
            prod_id: localStorage.getItem("product_id"),
            parent_task_id: currentTaskId,
            task_name: taskName,
            assigned_to: requiredAssignedData,
            start_date: startDate,
            due_date: duedate,
            status: status.value || "",
            priority: priority.value || "",
            milestone: milestone.value || "",
            progress: progress,
            description: desc,
            collaberators: [],
            is_active: true,
            task_id: false,
          },
        ];
        dispatch(
          postStrategyFunctions(data, "sub_task", false, false, false, true)
        );
        onClose();
      }
    } else {
      let assignedToList = [];
      if (Array.isArray(assignedTo)) {
        assignedToList = assignedTo;
      } else {
        assignedToList = [assignedTo];
      }
      const requiredAssignedData = empData.map((item) => ({
        key: item.value,
        is_active: assignedToList.some(
          (selectedItem) => selectedItem.value === item.value
        ),
      }));
      let data = [
        {
          bi_id: localStorage.getItem("bi_id"),
          prod_id: localStorage.getItem("product_id"),
          parent_task_id: isNewTask ? selectedTaskMilestoneId : milestone.value,
          task_name: taskName,
          assigned_to: requiredAssignedData,
          start_date: startDate,
          due_date: duedate,
          status: status.value || "",
          priority: priority.value || "",
          milestone: milestone.value || "",
          progress: progress,
          description: desc,
          collaberators: [],
          is_active: true,
          task_id: selectedTaskId,
        },
      ];
      dispatch(postStrategyFunctions(data, "task", false, false, false, true));
      onClose();
    }
  };

  const logo = createImageFromInitials(
    30,
    localStorage.getItem("full_name"),
    getRandomColor(localStorage.getItem("full_name"))
  );

  const addComment = (newComment) => {
    setComments([...comments, newComment]);
  };

  const handleEditComment = (name, newContent) => {
    const updatedComments = comments.map((comment) =>
      comment.name === name ? { ...comment, content: newContent } : comment
    );
    setComments(updatedComments);
  };

  const handleDeleteComment = (name) => {
    const updatedComments = comments.filter((comment) => comment.name !== name);
    setComments(updatedComments);
  };

  return (
    <div
      style={{
        width: fullScreenMode ? "calc(100% - 50px)" : "50%",
      }}
      className={"sidenav"}
    >
      <div
        className="gtm-sidebar-header"
        style={{
          borderBottom: "1px solid lightgrey",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: "1",
            minWidth: "70%",
            maxWidth: "80%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            margin: "0",
            paddingTop: "4px",
            fontSize: "var(--sub-heading-font-size)",
            fontWeight: "var(--heading-font-weight)",
          }}
          title={breadcrumbs}
        >
          {breadcrumbs}
        </div>
        {!fullScreenMode ? (
          <>
            {(selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin") && (
              <a href="#" className="sideMenu" onClick={handleMenuClick}>
                <FontAwesomeIcon icon={faEllipsisH} />
              </a>
            )}
            {isMenuOpen && (
              <div className="menu">
                <div className="Edit">
                  <div
                    className="DeleteOption"
                    onClick={() =>
                      !isNewSubTask ? handleTaskDelete() : handleSubTaskDelete()
                    }
                  >
                    <img
                      src={DeleteIcon}
                      width={"18px"}
                      style={{ marginRight: "10px" }}
                      alt="Edit Icon"
                    />
                    Delete
                  </div>
                </div>
              </div>
            )}
            <a href="#" className="fullscreen" onClick={handleFullScreen}>
              <AiOutlineCaretRight />
            </a>
          </>
        ) : (
          <>
            {(selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin") && (
              <a href="#" className="sideMenu" onClick={handleMenuClick}>
                <FontAwesomeIcon icon={faEllipsisH} />
              </a>
            )}
            <a href="#" className="fullscreen" onClick={handleSideNav}>
              <AiOutlineCaretLeft />
            </a>
          </>
        )}
        <a href="#" className="closebtn" onClick={onClose}>
          &times;
        </a>
      </div>
      <div className="gtm-sidebar-body">
        <div>
          <input
            required
            type="text"
            value={taskName}
            style={{
              padding: "6px 8px",
              marginLeft: "10px",
              width: "95%",
              border: "none",
              marginBottom: "10px",
              fontSize: "var(--task-title-font-size)",
            }}
            placeholder={
              isSubTaskSidebarOpen
                ? "Write a Sub task name"
                : "Write a task name"
            }
            onChange={(e) => setTaskName(e.target.value)}
            disabled={
              selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin"
                ? false
                : true
            }
          />
        </div>
        <div className="row">
          <div
            className="col-6"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              zIndex: 2000,
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                marginRight: "20px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Owner
            </div>
            <Select
              required
              name="emp_id"
              // isMulti
              // hideSelectedOptions
              // allowSelectAll
              className="select-owner"
              placeholder={
                <span style={{ fontSize: "var(--text-font-size)" }}>
                  Select Owner
                </span>
              }
              value={assignedTo}
              options={empData}
              onChange={handleOwnerChange}
              isSearchable={true}
              filterOption={customFilter}
              getOptionLabel={(e) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img alt="" src={e.icon} />
                  <span
                    style={{
                      marginLeft: 5,
                      fontSize: "var(--sub-heading-font-size)",
                    }}
                  >
                    {e.label}
                  </span>
                </div>
              )}
              isDisabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
              // menuPlacement="top"
            />
          </div>
          <div
            className="col-6"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              zIndex: 101,
            }}
          >
            <div
              style={{
                marginLeft: "20px",
                marginRight: "20px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Status
            </div>
            <Select
              required
              className="status-select"
              placeholder={
                <span style={{ fontSize: "var(--text-font-size)" }}>
                  Select Status
                </span>
              }
              value={status}
              options={statusData}
              onChange={handleStatusSelect}
              isSearchable={true}
              filterOption={customFilter}
              getOptionLabel={(e) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <img alt="" src={e.icon} /> */}
                  <span
                    style={{
                      marginLeft: 5,
                      fontSize: "var(--sub-heading-font-size)",
                    }}
                  >
                    {e.label}
                  </span>
                </div>
              )}
              isDisabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>
        </div>
        <div className="row">
          <div
            className="col-6"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                marginRight: "25px",
                textWrap: "nowrap",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Start Date
            </div>
            <input
              style={{
                width: "250px",
                fontSize: "var(--sub-heading-font-size)",
              }}
              type="date"
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              disabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>
          <div
            className="col-6"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginLeft: "20px",
                marginRight: "25px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Due Date
            </div>
            <input
              style={{
                width: "200px",
                fontSize: "var(--sub-heading-font-size)",
              }}
              type="date"
              className="form-control due-date-select"
              value={duedate}
              onChange={(e) => setDuedate(e.target.value)}
              disabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>

          <div
            className="col-6"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              zIndex: 100,
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                marginRight: "40px",
                marginBottom: 0,
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Priority
            </div>
            <Select
              required
              className="priority-select"
              placeholder={
                <span style={{ fontSize: "var(--text-font-size)" }}>
                  Select Priority
                </span>
              }
              value={priority}
              options={priorityData}
              onChange={handlePrioritySelect}
              isSearchable={true}
              filterOption={customFilter}
              getOptionLabel={(e) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <img alt="" src={e.icon} /> */}
                  <span
                    style={{
                      marginLeft: 5,
                      fontSize: "var(--sub-heading-font-size)",
                    }}
                  >
                    {e.label}
                  </span>
                </div>
              )}
              isDisabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>
        </div>
        <div className="row">
          <div
            className="col-12"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              zIndex: 3,
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                marginRight: "25px",
                marginBottom: 0,
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Milestone
            </div>
            <Select
              required
              className="milestone-select"
              placeholder={
                <span style={{ fontSize: "var(--text-font-size)" }}>
                  Select Milestone
                </span>
              }
              value={milestone}
              options={mileStonesData}
              onChange={handleMilestoneSelect}
              isSearchable={true}
              filterOption={customFilter}
              getOptionLabel={(e) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <img alt="" src={e.icon} /> */}
                  <span
                    style={{
                      marginLeft: 5,
                      fontSize: "var(--sub-heading-font-size)",
                    }}
                  >
                    {e.label}
                  </span>
                </div>
              )}
              isDisabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>
        </div>
        <div className="row">
          <div
            className="col-12"
            style={{
              marginBottom: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                marginRight: "20px",
                marginBottom: 0,
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Progress
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginLeft: "15px",
              }}
            >
              <StepProgressBar
                steps={steps}
                currentStep={currentStep}
                onStepClick={handleStepClick}
                selectedSubMenuPermission={selectedSubMenuPermission}
              />
              <div className="step-percentage">
                {(currentStep / (steps.length - 1)) * 100}%
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginLeft: "10px",
            marginRight: "30px",
            marginBottom: "0px",
            fontSize: "var(--sub-heading-font-size)",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          Description
        </div>
        <div style={{ margin: "10px 0px 40px 10px", height: "300px" }}>
          <QuillEditor
            value={desc}
            onChange={(content) => handleDescOnChange(content)}
            height={"300px"}
          />
        </div>

        {subtasks && subtasks.length > 0 && (
          <div>
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                      paddingLeft: "10px",
                      paddingTop: "10px",
                    }}
                  >
                    SubTasks
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {subtasks.map((subtask) => (
                  <tr>
                    <td
                      key={subtask.id}
                      style={{ textAlign: "left", paddingLeft: "10px" }}
                    >
                      {subtask.name}
                    </td>
                    <td>
                      <img
                        alt="chevronright"
                        src={ChevronRight}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleSubtaskSideBar(subtask.id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div
          style={{
            textAlign: "left",
            paddingLeft: "5px",
            paddingBottom: "10px",
            display: isNewTask ? "none" : "flex",
          }}
        >
          {isSubTaskVisible &&
            (selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin") && (
              <button
                style={{
                  display: "flex",
                  border: "none",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                  alignItems: "center",
                  marginTop: "10px",
                }}
                type="button"
                onClick={() => {
                  handleSubtaskNav();
                }}
              >
                <img
                  src={AddIcon}
                  width={16}
                  height={16}
                  style={{ marginRight: "5px" }}
                />
                Add Subtask
              </button>
            )}
        </div>
        <div
          style={{
            fontSize: "var(--text-font-size)",
            fontWeight: "var(--heading-font-weight)",
            paddingLeft: "10px",
            paddingTop: "10px",
          }}
        >
          Please Upload relevant documents
        </div>
        <UploadFiles selectedSubMenuPermission={selectedSubMenuPermission} />

        {isNewTask === false && (
          <div>
            <CommentSection
              currentUser={currentUser}
              logo={logo}
              fullScreenMode={fullScreenMode}
              taskId={selectedTaskId}
              commentsData={comments}
              onAddComment={addComment}
              onEditComment={handleEditComment}
              onDeleteComment={handleDeleteComment}
            />
          </div>
        )}
      </div>
      <div>
        <Button
          type="button"
          style={{
            position: "absolute",
            top: "8px",
            right: "150px",
            padding: "4px 10px",
            backgroundColor: "var(--button-primary-color)",
            fontSize: "var(--sub-heading-font-size)",
            border: "1px solid var(--button-primary-color)",
          }}
          onClick={() => handleSave()}
          disabled={
            selectedSubMenuPermission === "write" ||
            selectedSubMenuPermission === "admin"
              ? false
              : true
          }
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default TaskSidebar;
