import React, { useEffect, useState, useRef } from "react";
import * as cusAlert from "./Alert.js";
import { useDispatch, useSelector } from "react-redux";
import {
  resetExceptionError,
  postFolderManagerDelete,
  getDriveFiles,
  postMSDocs,
  postLocalFolderManager,
  getLocalFolderManager,
  postLocalFolderManagerFileDelete,
} from "../store/actions/index.js";
import Alert from "./Alert.js";
import { url } from "../store/sagas/API.js";
import UploadImage from "../assets/images/icon.svg";
import SheetImage from "../assets/images/icons8-excel.svg";
import DocImage from "../assets/images/icons8-microsoft-word.svg";
import PDFImage from "../assets/images/pdf-svgrepo-com.svg";
import PPTImage from "../assets/images/icons8-powerpoint.svg";
import TXTImage from "../assets/images/icons8-text.svg";
import OtherImage from "../assets/images/icons8-document.svg";
import NoDocImage from "../assets/images/nodoc.svg";
import DeleteIcon from "../assets/images/trash.svg";

const UploadFiles = ({
  selectedSubMenuPermission,
  questionId,
  function_id,
  product_id,
  attachments,
}) => {
  console.log("questionId", questionId);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [files, setFiles] = useState([]);
  const isPostDeleteSuccess = useSelector((state) => state.isPostDeleteSuccess);
  const isMSDocsPostDone = useSelector((state) => state.isMSDocsPostDone);
  const getDriveFilesData = useSelector((state) => state.getDriveFilesData);
  const isMSDocsPostFetched = useSelector((state) => state.isMSDocsPostFetched);
  const fetchLocalManagerData = useSelector(
    (state) => state.fetchLocalManagerData
  );
  const fetchpostLocalManagerData = useSelector(
    (state) => state.fetchpostLocalManagerData
  );
  const fetchpostLocalFolderManagerFileDelete = useSelector(
    (state) => state.fetchpostLocalFolderManagerFileDelete
  );
  // const isgetDriveFilesFetched = useSelector(
  //   (state) => state.isgetDriveFilesFetched
  // );

  // useEffect(() => {
  //   if (isPostDeleteSuccess) {
  //     dispatch(
  //       getDriveFiles(
  //         localStorage.getItem("bi_id"),
  //         localStorage.getItem("screen_id"),
  //         localStorage.getItem("main_screen_id")
  //       )
  //     );
  //     window.location.reload();
  //   }
  // }, [isPostDeleteSuccess]);
  // useEffect(() => {
  //   if (isMSDocsPostFetched) {
  //     dispatch(
  //       getDriveFiles(
  //         localStorage.getItem("bi_id"),
  //         localStorage.getItem("screen_id"),
  //         localStorage.getItem("main_screen_id")
  //       )
  //     );
  //     window.location.reload();
  //   }
  // }, [isMSDocsPostFetched]);

  // useEffect(() => {
  //   dispatch(
  //     getDriveFiles(
  //       localStorage.getItem("bi_id"),
  //       localStorage.getItem("screen_id"),
  //       localStorage.getItem("main_screen_id")
  //     )
  //   );
  // }, []);

  useEffect(() => {
    if (!questionId) {
      dispatch(
        getLocalFolderManager(
          localStorage.getItem("bi_id"),
          localStorage.getItem("screen_id"),
          localStorage.getItem("main_screen_id")
        )
      );
    } else {
      setFiles(attachments);
    }
  }, []);

  useEffect(() => {
    if (fetchLocalManagerData) {
      if (fetchLocalManagerData.data) {
        if (!questionId) {
          setFiles(fetchLocalManagerData.data);
        } else {
          window.location.reload();
        }
      }
    }
  }, [fetchLocalManagerData]);

  useEffect(() => {
    if (fetchpostLocalManagerData && fetchpostLocalManagerData.status === 200) {
      if (!questionId) {
        dispatch(
          getLocalFolderManager(
            localStorage.getItem("bi_id"),
            localStorage.getItem("screen_id"),
            localStorage.getItem("main_screen_id")
          )
        );
      } else {
        window.location.reload();
      }
    }
  }, [fetchpostLocalManagerData]);

  useEffect(() => {
    if (
      fetchpostLocalFolderManagerFileDelete &&
      fetchpostLocalFolderManagerFileDelete.status === 200
    ) {
      if (!questionId) {
        dispatch(
          getLocalFolderManager(
            localStorage.getItem("bi_id"),
            localStorage.getItem("screen_id"),
            localStorage.getItem("main_screen_id")
          )
        );
      } else {
        window.location.reload();
      }
    }
  }, [fetchpostLocalFolderManagerFileDelete]);

  // useEffect(() => {
  //   if (isgetDriveFilesFetched) {
  //     if (getDriveFilesData.data) {
  //       setFiles(getDriveFilesData.data);
  //     }
  //   }
  // }, [isgetDriveFilesFetched]);

  // const handleDeleteFile = (id) => {
  //   cusAlert.default.warning({
  //     title: "Warning",
  //     // html: ISError,
  //     text: "Are you sure do you want to delete the File?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     preConfirm: (isConfirm) => {
  //       if (isConfirm) {
  //         dispatch(postFolderManagerDelete(id));
  //         dispatch(resetExceptionError());
  //       }
  //     },
  //   });
  // };

  const handleDeleteFile = (id) => {
    cusAlert.default.warning({
      title: "Warning",
      // html: ISError,
      text: "Are you sure do you want to delete the File?",
      icon: "warning",
      showCancelButton: true,
      preConfirm: (isConfirm) => {
        if (isConfirm) {
          dispatch(postLocalFolderManagerFileDelete(id));
          dispatch(resetExceptionError());
        }
      },
    });
  };

  const bi_id = localStorage.getItem("bi_id");
  const onButtonClick = () => {
    inputRef.current.click();
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };
  const [isMSDocsSend, setIsMSDocsSend] = useState(false);
  // function handleFile(files) {
  //   for (let i = 0; i < files.length; i++) {
  //     const file = files[i];
  //     setIsMSDocsSend(!isMSDocsSend);
  //     dispatch(
  //       postMSDocs(
  //         localStorage.getItem("bi_id"),
  //         localStorage.getItem("screen_id"),
  //         localStorage.getItem("main_screen_id"),
  //         file,
  //         "XL_strategy_plan",
  //         isMSDocsSend
  //       )
  //     );
  //   }
  // }
  function handleFile(files) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      setIsMSDocsSend(!isMSDocsSend);
      if (questionId && function_id && product_id) {
        console.log("if condition");
        dispatch(
          postLocalFolderManager(
            localStorage.getItem("bi_id"),
            localStorage.getItem("screen_id"),
            localStorage.getItem("main_screen_id"),
            file,
            questionId,
            function_id,
            product_id
          )
        );
      } else {
        console.log("else condition");
        dispatch(
          postLocalFolderManager(
            localStorage.getItem("bi_id"),
            localStorage.getItem("screen_id"),
            localStorage.getItem("main_screen_id"),
            file,
            "",
            "",
            ""
          )
        );
      }
    }
  }
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  return (
    <div>
      <div className="row">
        <div>
          <form
            id="form-file-upload"
            onDragEnter={
              selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin"
                ? handleDrag
                : null
            }
            onSubmit={(e) => e.preventDefault()}
            style={{
              height: "60px",
              width: "80%",
              margin: "auto",
              marginBottom: "20px",
              marginTop: "10px",
            }}
          >
            <input
              ref={inputRef}
              type="file"
              id="input-file-upload"
              multiple={true}
              onChange={handleChange}
              disabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
            <label
              id="label-file-upload"
              htmlFor="input-file-upload"
              className={dragActive ? "drag-active" : ""}
            >
              <div>
                <button
                  className="upload-button"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default form submission
                    onButtonClick();
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor:
                      selectedSubMenuPermission === "write" ||
                      selectedSubMenuPermission === "admin"
                        ? "pointer"
                        : "default",
                    textDecoration:
                      selectedSubMenuPermission === "write" ||
                      selectedSubMenuPermission === "admin"
                        ? ""
                        : "none",
                  }}
                >
                  <div>
                    <img src={UploadImage} />
                  </div>
                  <span
                    style={{
                      color: "#0DA14B",
                      marginLeft: "5px",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                    }}
                  >
                    Upload a file
                  </span>
                  <span
                    style={{
                      paddingLeft: "10px",
                      fontSize: "var(--sub-heading-font-size)",
                    }}
                  >
                    or drag and drop a file
                  </span>
                </button>
              </div>
            </label>
            {dragActive && (
              <div
                id="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </form>
          <div>
            {files.length > 0 ? (
              <>
                <table className="bordered-table-upload-files">
                  <thead
                    style={{
                      backgroundColor: "#F6F8FA",
                    }}
                  >
                    <tr>
                      <th>File</th>
                      <th>Modified</th>
                      <th>Modified By</th>
                      {selectedSubMenuPermission === "admin" && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {files.length > 0 &&
                      files.map((obj) => (
                        <tr key={obj.name}>
                          <td style={{ textAlign: "left" }}>
                            <a
                              href={obj.file_url}
                              target="_blank"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                textDecoration: "none",
                              }}
                            >
                              <img
                                src={
                                  obj.img
                                    ? url + obj.img
                                    : obj.file_name.split(".").pop() ===
                                        "xls" ||
                                      obj.file_name.split(".").pop() ===
                                        "xlsx" ||
                                      obj.file_name.split(".").pop() === "csv"
                                    ? SheetImage
                                    : obj.file_name.split(".").pop() === "pdf"
                                    ? PDFImage
                                    : obj.file_name.split(".").pop() ===
                                        "doc" ||
                                      obj.file_name.split(".").pop() === "docx"
                                    ? DocImage
                                    : obj.file_name.split(".").pop() === "ppt"
                                    ? PPTImage
                                    : OtherImage
                                }
                                style={{
                                  width: 25,
                                  height: 25,
                                }}
                              />
                              <label
                                style={{
                                  color: "black",
                                  cursor: "pointer",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  fontSize: "var(--text-font-size)",
                                  width: "300px",
                                }}
                              >
                                {obj.file_name}
                              </label>
                            </a>
                          </td>
                          <td style={{ fontSize: "var(--text-font-size)" }}>
                            {obj.modified}
                          </td>
                          <td style={{ fontSize: "var(--text-font-size)" }}>
                            {obj.modified_by}
                          </td>
                          {selectedSubMenuPermission === "admin" && (
                            <td>
                              <span
                                className="folder-managementicons"
                                title="Delete"
                                onClick={() => handleDeleteFile(obj.name)}
                              >
                                <img src={DeleteIcon} width={14} />
                              </span>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <label
                  style={{
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                    marginLeft: "10px",
                  }}
                >
                  All Documents
                </label>
                <div style={{ textAlign: "center" }}>
                  <img src={NoDocImage} />
                  <div>
                    <label
                      style={{
                        fontSize: "var(--sub-heading-font-size)",
                        fontWeight: "var(--heading-font-weight)",
                      }}
                    >
                      No Documents
                    </label>
                  </div>
                  <div>
                    <label
                      style={{
                        fontSize: "var(--sub-heading-font-size)",
                      }}
                    >
                      Uploaded documents will appear here
                    </label>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadFiles;
